import { COMMON_FIELDS } from './common';

const FILES_FIELD = {
  ID: COMMON_FIELDS.ID,
  LENGTH: 'length',
  CHUNK_SIZE: 'chunkSize',
  UPLOAD_DATE: 'uploadDate',
  FILE_NAME: 'filename',
  CONTENT_TYPE: 'contentType',
  PROGRAM_ID: 'programId',
  USER_ID: 'userId',
  MATCH_ID: 'matchId',
};

const FILE_CHUNK_FIELD = {
  ID: COMMON_FIELDS.ID,
  FILES_ID: 'files_id',
  N: 'n',
  DATA: 'data',
};

const FILES_ENUM = {
  TYPE: {
    PDF: 'PDF',
  },
};

export { FILES_FIELD, FILES_ENUM, FILE_CHUNK_FIELD };
