/**
 * reducers/index.js
 * Defines the root reducer (root store)
 * This module is to combine all the reducers and merge them. the root reducer will be passed to react elements that connect to the store.
 */
import { combineReducers } from 'redux';
import version from './version';
import auth from './auth';
import program from './program';
import token from './token';
import file from './file';

const rootReducer = combineReducers({
  version,
  auth,
  program,
  token,
  file,
});

export default rootReducer;
