/**
 * App's token reducer
 */
import ACTIONS from '../actions/actionsType';
import { REDUCER_STATE } from '../../constants';
import { findIndex } from 'lodash';
import { FILES_FIELD } from '../../constants/db';

const { FIELDS } = REDUCER_STATE.FILE;

export default (
  state = {
    [FIELDS.IS_UPLOADING]: false,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.IS_DELETING]: false,
    [FIELDS.IS_DOWNLOADING]: false,
    [FIELDS.FILE]: null,
    [FIELDS.FILES]: [],
  },
  action
) => {
  switch (action.type) {
    case ACTIONS.UPLOAD_FILE: {
      return {
        ...state,
        [FIELDS.IS_UPLOADING]: true,
      };
    }
    case ACTIONS.UPLOAD_FILE_FULFILLED: {
      const current = state[FIELDS.FILES];
      return {
        ...state,
        [FIELDS.IS_UPLOADING]: false,
        [FIELDS.FILES]: [...current, action.payload],
      };
    }
    case ACTIONS.UPLOAD_FILE_REJECTED: {
      return {
        ...state,
        [FIELDS.IS_UPLOADING]: false,
        [FIELDS.ERROR]: action.payload,
      };
    }

    case ACTIONS.GET_FILES: {
      return {
        ...state,
        [FIELDS.IS_GETTING]: true,
        [FIELDS.FILES]: [],
      };
    }
    case ACTIONS.GET_FILES_FULFILLED: {
      return {
        ...state,
        [FIELDS.IS_GETTING]: false,
        [FIELDS.FILES]: action.payload,
      };
    }
    case ACTIONS.GET_FILES_REJECTED: {
      return {
        ...state,
        [FIELDS.IS_GETTING]: false,
        [FIELDS.ERROR]: action.payload,
      };
    }

    case ACTIONS.DOWNLOAD_FILE: {
      return {
        ...state,
        [FIELDS.IS_DOWNLOADING]: true,
        [FIELDS.FILE]: null,
      };
    }
    case ACTIONS.DOWNLOAD_FILE_FULFILLED: {
      return {
        ...state,
        [FIELDS.IS_DOWNLOADING]: false,
        [FIELDS.FILE]: action.payload,
      };
    }
    case ACTIONS.DOWNLOAD_FILE_REJECTED: {
      return {
        ...state,
        [FIELDS.IS_DOWNLOADING]: false,
        [FIELDS.ERROR]: action.payload,
      };
    }

    case ACTIONS.DELETE_FILE: {
      return {
        ...state,
        [FIELDS.IS_DELETING]: true,
      };
    }
    case ACTIONS.DELETE_FILE_FULFILLED: {
      const current = state[FIELDS.FILES];
      const id = action.payload;
      const index = findIndex(current, { [FILES_FIELD.ID]: id });
      let newList = [...current];
      if (index !== -1) {
        newList = current.filter((each) => each[FILES_FIELD.ID] !== id);
      }
      return {
        ...state,
        [FIELDS.IS_DELETING]: false,
        [FIELDS.FILES]: newList,
      };
    }
    case ACTIONS.DELETE_FILE_REJECTED: {
      return {
        ...state,
        [FIELDS.IS_DELETING]: false,
        [FIELDS.ERROR]: action.payload,
      };
    }
    default:
      return state;
  }
};
