import './App.less';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getVersion } from './store/actions/version';
import RootRoute from './routes/RootRoute';

/**
 * Application with Router
 * @return {JSX.Element}
 */
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVersion());
  }, [dispatch]);

  return (
    <div className='App'>
      <RootRoute />
    </div>
  );
}

export default App;
