import { COMMON_FIELDS } from './common';

const TOKEN_FIELD = {
  ...COMMON_FIELDS,
  TYPE: 'type',
  USER_ID: 'userId',
  PROGRAM_ID: 'programId',
  MATCH_ID: 'matchId',
  VALUE: 'value',
  PAYLOAD: 'payload',
  EXPIRES_IN: 'expiresIn',
};

const TOKEN_ENUM = {
  TYPE: {
    REPORT: 'report',
  },
};

export { TOKEN_FIELD, TOKEN_ENUM };
