const REDUCER_STATE = {
  PROGRAM: {
    NAME: 'program',
    FIELDS: {
      CURRENT_PROGRAM: 'currentProgram',
      PROGRAM_USERS: 'programUsers',
      MATCHED_RESULTS: 'matchedResults',
      IS_GETTING_USERS: 'isGettingUsers',
      IS_GETTING_PROGRAMS: 'isGettingPrograms',
      IS_GETTING_MATCH_RESULTS: 'isGettingMatchResults',
      IS_UPDATING_BRIEFING_DATE: 'isUpdatingBriefingDate',
      ERROR: 'error',
    },
  },
  TOKEN: {
    NAME: 'token',
    FIELDS: {
      TOKEN: 'token',
      IS_VERIFYING: 'isVerifying',
      ERROR: 'error',
    },
  },
  FILE: {
    NAME: 'file',
    FIELDS: {
      FILES: 'files',
      FILE: 'file',
      IS_GETTING: 'isGetting',
      IS_DOWNLOADING: 'isDownloading',
      IS_UPLOADING: 'isUploading',
      IS_DELETING: 'isDeleting',
      ERROR: 'error',
    },
  },
};

export { REDUCER_STATE };
