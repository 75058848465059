/**
 * Actions type
 */
export default {
  // Version actions
  GET_VERSION: 'GET_VERSION',

  // Auth actions
  LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
  LOG_OUT_SUCCESS: 'LOG_OUT_SUCCESS',

  // Program actions
  GET_PROGRAMS: 'GET_PROGRAMS',
  GET_PROGRAM: 'GET_PROGRAM',
  GET_PROGRAM_FULFILLED: 'GET_PROGRAM_FULFILLED',
  GET_PROGRAM_REJECTED: 'GET_PROGRAM_REJECTED',

  // Program users actions
  GET_PROGRAM_USERS: 'GET_PROGRAM_USERS',
  GET_PROGRAM_USERS_FULFILLED: 'GET_PROGRAM_USERS_FULFILLED',
  GET_PROGRAM_USERS_REJECTED: 'GET_PROGRAM_USERS_REJECTED',
  DELETE_PROGRAM_USERS: 'DELETE_PROGRAM_USERS',
  UPDATE_PROGRAM_USER: 'UPDATE_PROGRAM_USER',

  // Matching
  GET_MATCHING: 'GET_MATCHING',
  GET_MATCHING_FULFILLED: 'GET_MATCHING_FULFILLED',
  GET_MATCHING_REJECTED: 'GET_MATCHING_REJECTED',
  RESET_MATCHING: 'RESET_MATCHING',
  UPDATE_BRIEFING_SESSION_COMPLETION_DATE: 'UPDATE_BRIEFING_SESSION_COMPLETION_DATE',
  UPDATE_BRIEFING_SESSION_COMPLETION_DATE_FULFILLED:
    'UPDATE_BRIEFING_SESSION_COMPLETION_DATE_FULFILLED',
  UPDATE_BRIEFING_SESSION_COMPLETION_DATE_REJECTED:
    'UPDATE_BRIEFING_SESSION_COMPLETION_DATE_REJECTED',
  UPDATE_MATCH: 'UPDATE_MATCH',
  DELETE_MATCH: 'DELETE_MATCH',
  SEND_REPORT_LINK_EMAIL: 'SEND_REPORT_LINK_EMAIL',
  SEND_REPORT_LINK_EMAIL_FULFILLED: 'SEND_REPORT_LINK_EMAIL_FULFILLED',
  SEND_REPORT_LINK_EMAIL_REJECTED: 'SEND_REPORT_LINK_EMAIL_REJECTED',

  // Reminder
  CREATE_PROGRAM_REMINDER: 'CREATE_PROGRAM_REMINDER',
  DELETE_PROGRAM_REMINDER: 'DELETE_PROGRAM_REMINDER',
  GET_PROGRAM_REMINDERS: 'GET_PROGRAM_REMINDERS',
  UPDATE_PROGRAM_REMINDER: 'UPDATE_PROGRAM_REMINDER',
  SEND_PROGRAM_REMINDER: 'SEND_PROGRAM_REMINDER',

  // Template
  GET_TEMPLATES: 'GET_TEMPLATES',
  UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',

  // Token
  VERIFY_TOKEN: 'VERIFY_TOKEN',
  VERIFY_TOKEN_FULFILLED: 'VERIFY_TOKEN_FULFILLED',
  VERIFY_TOKEN_REJECTED: 'VERIFY_TOKEN_REJECTED',

  // File
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_FILE_FULFILLED: 'UPLOAD_FILE_FULFILLED',
  UPLOAD_FILE_REJECTED: 'UPLOAD_FILE_REJECTED',
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  DOWNLOAD_FILE_FULFILLED: 'DOWNLOAD_FILE_FULFILLED',
  DOWNLOAD_FILE_REJECTED: 'DOWNLOAD_FILE_REJECTED',
  DELETE_FILE: 'DELETE_FILE',
  DELETE_FILE_FULFILLED: 'DELETE_FILE_FULFILLED',
  DELETE_FILE_REJECTED: 'DELETE_FILE_REJECTED',
  GET_FILES: 'GET_FILES',
  GET_FILES_FULFILLED: 'GET_FILES_FULFILLED',
  GET_FILES_REJECTED: 'GET_FILES_REJECTED',
};
