/**
 * This module defined any action function related to the app's version
 */
import api from '../../api';
import ENDPOINTS from '../../api/endpoints';
import ACTIONS from './actionsType';

/**
 * Get app's version
 * @return {function(*): *}
 */
export const getVersion = () => (dispatch) => {
  return api.get(ENDPOINTS.VERSION).then((res) => {
    const {
      data: {
        data: { version },
      },
    } = res;
    dispatch({ type: ACTIONS.GET_VERSION, version });
  });
};
