/**
 * Define a program reducer.
 */
import ACTIONS from '../actions/actionsType';
import { REDUCER_STATE } from '../../constants';
import { MATCH_RESULT_FIELD } from '../../constants/db';

const { FIELDS } = REDUCER_STATE.PROGRAM;

export default (
  state = {
    programs: [],
    [FIELDS.CURRENT_PROGRAM]: null,
    [FIELDS.PROGRAM_USERS]: [],
    [FIELDS.MATCHED_RESULTS]: [],
    reminders: [],
    templates: [],
    [FIELDS.IS_GETTING_USERS]: false,
    [FIELDS.IS_GETTING_PROGRAMS]: false,
    [FIELDS.IS_GETTING_MATCH_RESULTS]: false,
    [FIELDS.IS_UPDATING_BRIEFING_DATE]: false,
    [FIELDS.ERROR]: null,
  },
  action
) => {
  switch (action.type) {
    case ACTIONS.DELETE_PROGRAM_USERS: {
      return {
        ...state,
        programUsers: [],
      };
    }
    case ACTIONS.GET_PROGRAMS: {
      return {
        ...state,
        programs: action.programs,
      };
    }
    case ACTIONS.GET_PROGRAM: {
      return {
        ...state,
        [FIELDS.CURRENT_PROGRAM]: null,
        [FIELDS.IS_GETTING_PROGRAMS]: true,
      };
    }
    case ACTIONS.GET_PROGRAM_FULFILLED: {
      return {
        ...state,
        [FIELDS.CURRENT_PROGRAM]: action.program,
        [FIELDS.IS_GETTING_PROGRAMS]: false,
      };
    }
    case ACTIONS.GET_PROGRAM_REJECTED: {
      return {
        ...state,
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_GETTING_PROGRAMS]: false,
      };
    }

    case ACTIONS.GET_PROGRAM_USERS: {
      return {
        ...state,
        [FIELDS.PROGRAM_USERS]: [],
        [FIELDS.IS_GETTING_USERS]: true,
      };
    }
    case ACTIONS.GET_PROGRAM_USERS_FULFILLED: {
      return {
        ...state,
        [FIELDS.IS_GETTING_USERS]: false,
        [FIELDS.PROGRAM_USERS]: action.users,
      };
    }
    case ACTIONS.GET_PROGRAM_USERS_REJECTED: {
      return {
        ...state,
        [FIELDS.IS_GETTING_USERS]: false,
        [FIELDS.ERROR]: action.payload,
      };
    }
    case ACTIONS.UPDATE_PROGRAM_USER: {
      const newProgramUsers = state[FIELDS.PROGRAM_USERS].map((each) => {
        if (each._id === action.updatedUser._id) {
          return action.updatedUser;
        }
        return each;
      });
      return {
        ...state,
        programUsers: newProgramUsers,
      };
    }

    case ACTIONS.GET_MATCHING: {
      return {
        ...state,
        [FIELDS.IS_GETTING_MATCH_RESULTS]: true,
        [FIELDS.MATCHED_RESULTS]: [],
      };
    }
    case ACTIONS.GET_MATCHING_FULFILLED: {
      return {
        ...state,
        [FIELDS.IS_GETTING_MATCH_RESULTS]: false,
        [FIELDS.MATCHED_RESULTS]: action.matchedResults,
      };
    }
    case ACTIONS.GET_MATCHING_REJECTED: {
      return {
        ...state,
        [FIELDS.IS_GETTING_MATCH_RESULTS]: false,
        [FIELDS.ERROR]: action.payload,
      };
    }

    case ACTIONS.RESET_MATCHING: {
      return {
        ...state,
        [FIELDS.MATCHED_RESULTS]: [],
      };
    }

    case ACTIONS.UPDATE_BRIEFING_SESSION_COMPLETION_DATE: {
      return {
        ...state,
        [FIELDS.ERROR]: null,
        [FIELDS.IS_UPDATING_BRIEFING_DATE]: true,
      };
    }
    case ACTIONS.UPDATE_BRIEFING_SESSION_COMPLETION_DATE_FULFILLED: {
      const newMatch = state[FIELDS.MATCHED_RESULTS].map((each) => {
        if (each?.[MATCH_RESULT_FIELD.ID] === action.payload?.[MATCH_RESULT_FIELD.ID]) {
          return { ...each, ...action.payload };
        }
        return each;
      });
      return {
        ...state,
        [FIELDS.MATCHED_RESULTS]: newMatch,
        [FIELDS.IS_UPDATING_BRIEFING_DATE]: false,
      };
    }
    case ACTIONS.UPDATE_BRIEFING_SESSION_COMPLETION_DATE_REJECTED: {
      return {
        ...state,
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_UPDATING_BRIEFING_DATE]: false,
      };
    }

    case ACTIONS.UPDATE_MATCH: {
      const newMatch = state[FIELDS.MATCHED_RESULTS].map((each) => {
        if (each._id === action.updatedMatch._id) {
          return { ...each, ...action.updatedMatch };
        }
        return each;
      });
      return {
        ...state,
        matchedResults: newMatch,
      };
    }
    case ACTIONS.DELETE_MATCH: {
      const matchedResults = state[FIELDS.MATCHED_RESULTS].filter(
        (each) => each._id !== action.deletedMatchId
      );
      return {
        ...state,
        matchedResults,
      };
    }
    case ACTIONS.CREATE_PROGRAM_REMINDER: {
      const newReminders = [...state.reminders, action.createdReminder];
      newReminders.sort((a, b) => new Date(a.sendDate) - new Date(b.sendDate));
      return {
        ...state,
        reminders: newReminders,
      };
    }
    case ACTIONS.GET_PROGRAM_REMINDERS: {
      return {
        ...state,
        reminders: action.reminders,
      };
    }
    case ACTIONS.UPDATE_PROGRAM_REMINDER: {
      const newReminder = state.reminders.map((each) => {
        if (each._id === action.updatedReminder._id) {
          return action.updatedReminder;
        }
        return each;
      });
      return {
        ...state,
        reminders: newReminder,
      };
    }
    case ACTIONS.DELETE_PROGRAM_REMINDER: {
      const newReminders = state.reminders.filter((each) => each._id !== action.deletedReminderID);
      return {
        ...state,
        reminders: newReminders,
      };
    }
    case ACTIONS.SEND_PROGRAM_REMINDER: {
      const updatedReminder = action.reminder;
      const totalArray = [...state.reminders, updatedReminder];
      const newReminders = totalArray.reduce((acc, curr) => {
        const i = acc.findIndex(({ _id }) => _id === curr._id);
        if (i === -1) acc.push(curr);
        else acc[i] = curr;
        return acc;
      }, []);

      return {
        ...state,
        reminders: newReminders,
      };
    }
    case ACTIONS.GET_TEMPLATES: {
      return {
        ...state,
        templates: action.templates,
      };
    }
    case ACTIONS.UPDATE_TEMPLATE: {
      const newTemplate = state.templates.map((each) => {
        if (each._id === action.updatedTemplate._id) {
          return action.updatedTemplate;
        }
        return each;
      });
      return {
        ...state,
        templates: newTemplate,
      };
    }
    default:
      return state;
  }
};
