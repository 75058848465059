import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST || '/',
  timeout: 20000,
});

instance.interceptors.request.use((config) => {
  // Set Authorization header
  if (localStorage.getItem('token')) {
    config.headers.common['Authorization'] = `${localStorage.getItem('token')}`;
  }
  return config;
});

export default instance;
