/**
 * Define a auth reducer.
 * The reducer takes an incoming action object { type: 'name of type', data: someData}. Update the record in
 * the redux store based on the type of the action.
 */
import ACTIONS from '../actions/actionsType';

export default (
  state = {
    user: null,
  },
  action
) => {
  switch (action.type) {
    case ACTIONS.LOG_IN_SUCCESS: {
      return {
        ...state,
        user: action.user,
      };
    }
    case ACTIONS.LOG_OUT_SUCCESS: {
      return {
        ...state,
        user: null,
      };
    }
    default:
      return state;
  }
};
