/**
 * App's token reducer
 */
import ACTIONS from '../actions/actionsType';
import { REDUCER_STATE } from '../../constants';

const { FIELDS } = REDUCER_STATE.TOKEN;

export default (
  state = {
    [FIELDS.IS_VERIFYING]: false,
    [FIELDS.TOKEN]: null,
    [FIELDS.ERROR]: null,
  },
  action
) => {
  switch (action.type) {
    case ACTIONS.VERIFY_TOKEN: {
      return {
        ...state,
        [FIELDS.IS_VERIFYING]: true,
        [FIELDS.TOKEN]: null,
      };
    }
    case ACTIONS.VERIFY_TOKEN_FULFILLED: {
      return {
        ...state,
        [FIELDS.IS_VERIFYING]: false,
        [FIELDS.TOKEN]: action.payload,
      };
    }
    case ACTIONS.VERIFY_TOKEN_REJECTED: {
      return {
        ...state,
        [FIELDS.IS_VERIFYING]: false,
        [FIELDS.ERROR]: action.payload,
      };
    }
    default:
      return state;
  }
};
