export default {
  VERSION: '/api/version',
  LOG_IN: '/api/login',
  LOG_OUT: 'api/logout',
  USER_CURRENT: 'api/user/current',
  PROGRAMS: 'api/program',
  TOKENS: 'api/tokens',
  FILES: 'api/files',
  FILE_DOWNLOAD: 'api/file-download',
};
