const ROUTES = {
  ROOT: '/',
  LOG_IN: '/login',
  ARCHIVED: '/archived',
  PROGRAM: '/program/:id',
  TIME_TABLE: '/program/:id/users/:userId/timetable',
  SCHEDULE_MATCH: '/program/:id/schedule/:scheduleId',
  FILE_UPLOAD_PAGE: '/file-upload',
};

export { ROUTES };
