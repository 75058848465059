import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../constants/routes';

const TokenRoute = lazy(() => import('../routes/TokenRoute'));
const PrivateRoute = lazy(() => import('../routes/PrivateRoute'));
const LoginPage = lazy(() => import('../pages/LoginPage'));
const HomePage = lazy(() => import('../pages/HomePage'));
const ProgramPage = lazy(() => import('../pages/ProgramPage'));
const TimetablePage = lazy(() => import('../pages/TimetablePage'));
const ScheduleMatchPage = lazy(() => import('../pages/ScheduleMatchPage'));
const FileUploadPage = lazy(() => import('../pages/FileUploadPage'));

const RootRoute = () => {
  return (
    <Router>
      <Suspense fallback={<div />}>
        <Switch>
          <Route exact path={ROUTES.LOG_IN} component={LoginPage} />
          <Route exact path='/program/:id/users/:userId/timetable' component={TimetablePage} />
          <Route exact path='/program/:id/schedule/:scheduleId' component={ScheduleMatchPage} />
          <PrivateRoute exact path='/' component={HomePage} />
          <PrivateRoute exact path='/archived' component={HomePage} />
          <PrivateRoute path='/program/:id' component={ProgramPage} />
          <TokenRoute exact path={ROUTES.FILE_UPLOAD_PAGE} component={FileUploadPage} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default RootRoute;
