import { COMMON_FIELDS } from './common';

const MATCH_RESULT_FIELD = {
  ...COMMON_FIELDS,
  BRIEFING_SESSION_COMPLETION_DATE: 'briefingSessionCompletionDate',
  REVIEWER_ID: 'reviewerId',
  REVIEWEE_ID: 'revieweeId',
  FILES: 'files',
};

export { MATCH_RESULT_FIELD };
