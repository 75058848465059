/**
 * App's version reducer
 */
import ACTIONS from '../actions/actionsType';

export default (
  state = {
    version: '',
  },
  action
) => {
  switch (action.type) {
    case ACTIONS.GET_VERSION: {
      return {
        ...state,
        version: action.version,
      };
    }
    default:
      return state;
  }
};
